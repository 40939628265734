<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-house"></i>Tipos de Comprador
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nuevo Tipo Comprador</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Nombre">
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "tipos-comprador",
  components: {
    ModalNuevo,
    ModalModificar
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/tipoCompradorSecco/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el tipos de comprador."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { tipoCompradorSeccoID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/tipoCompradorSecco/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Tipo de comprador borrado con éxito!",
          type: "success"
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //
    }
  }
};
</script>

